import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "../scss/components/_news.scss";

const NewsPreview = ({ id, title, date, mediaType, mediaUrl }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  // Закрытие модального окна
  const handleClose = () => setIsOpen(false);

  // Закрытие при нажатии клавиши Escape
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        handleClose();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="newsPreviewBox">
      <div className="content">
        <div
          className="newsImageBox"
          onClick={() => setIsOpen(true)}
          style={{ cursor: "pointer" }}
        >
          {mediaType === "image" ? (
            <img src={mediaUrl} alt="" loading="lazy" />
          ) : mediaType === "video" ? (
            <video className="videoTag" autoPlay loop muted>
              <source src={mediaUrl} type="video/mp4" />
            </video>
          ) : null}
        </div>
        <div className="newsPreviewTitleBox">
          <div className="date">
            <p className="date-text">{date}</p>
          </div>
          <h3>{title}</h3>
        </div>
      </div>

      {/* Модальное окно для изображений */}
      {isOpen && mediaType === "image" && (
        <div className="lightboxOverlay" onClick={handleClose}>
          <div className="lightboxContent" onClick={(e) => e.stopPropagation()}>
            <Lightbox
              open={isOpen}
              close={handleClose}
              slides={[{ src: mediaUrl }]}
              styles={{
                container: { backgroundColor: "rgba(0, 0, 0, 0.8)" },
              }}
              controller={{
                touchAction: "none",
                closeOnBackdropClick: true,
              }}
              carousel={{
                preload: 0,
                finite: true,
              }}
            />
          </div>
        </div>
      )}

      {/* Модальное окно для видео */}
      {isOpen && mediaType === "video" && (
        <div className="videoLightbox" onClick={handleClose}>
          <div className="videoOverlay" />
          <div className="videoContent" onClick={(e) => e.stopPropagation()}>
            <video
              controls
              autoPlay
              className="modal-video"
              onWheel={(e) => e.stopPropagation()}
            >
              <source src={mediaUrl} type="video/mp4" />
            </video>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsPreview;
