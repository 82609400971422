import React, { useState, useRef, useEffect } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "../scss/components/_news.scss";

const NewsGalleryComponent = ({ title, date, mediaType, mediaUrl }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const videoRef = useRef(null);
  const modalVideoRef = useRef(null);
  const isClosingRef = useRef(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const pauseAllVideos = () => {
    document.querySelectorAll("video").forEach((video) => {
      video.pause();
      video.muted = true;
    });
  };

  useEffect(() => {
    if (isOpen && modalVideoRef.current) {
      pauseAllVideos();
      modalVideoRef.current
        .play()
        .catch((error) => console.log("Play prevented:", error));
    }
  }, [isOpen]);

  const handleOpenModal = () => {
    if (isClosingRef.current) return;
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    if (isClosingRef.current) return;
    isClosingRef.current = true;

    setIsOpen(false);

    if (document.fullscreenElement) document.exitFullscreen();

    if (modalVideoRef.current) {
      modalVideoRef.current.pause();
      modalVideoRef.current.currentTime = 0;
    }

    setTimeout(() => {
      isClosingRef.current = false;
    }, 300);
  };

  const renderSlide = ({ slide }) => {
    if (slide.type === "video") {
      return (
        <div className="lightbox-slide">
          <div className="lightbox-video-container">
            <video
              ref={modalVideoRef}
              controls
              autoPlay
              playsInline
              className="lightbox-media"
              onClick={(e) => e.stopPropagation()}
              onWheel={(e) => e.stopPropagation()}
              loop
              style={{ objectFit: "contain" }}
            >
              <source src={slide.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          {/* Убираем заголовок и дату в мобильной версии */}
          {!isMobile && (
            <div className="lightbox-caption">
              <p className="newsGalleryTitle">{title}</p>
              <p className="newsDate">{date}</p>
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="lightbox-slide">
        <img
          src={slide.src}
          alt={title}
          className="lightbox-media"
          onClick={(e) => e.stopPropagation()}
          style={{ objectFit: "contain" }}
        />
        {!isMobile && (
          <div className="lightbox-caption">
            <p className="newsGalleryTitle">{title}</p>
            <p className="newsDate">{date}</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="newsGalleryBox">
      <div
        className="newsGalleryImageBox"
        onClick={handleOpenModal}
        style={{ cursor: "pointer" }}
      >
        {mediaType === "image" ? (
          <img
            src={mediaUrl}
            alt={title}
            loading="lazy"
            className="gallery-media"
            style={{ objectFit: "contain" }}
          />
        ) : (
          <video
            className="gallery-media"
            ref={videoRef}
            playsInline
            muted
            autoPlay
            loop
            style={{ objectFit: "contain" }}
          >
            <source src={mediaUrl} type="video/mp4" />
          </video>
        )}
      </div>
      <div className="newsGalleryTitleBox">
        {title && <p className="newsGalleryTitle">{title}</p>}
      </div>
      <div className="newsDate">
        <p>{date}</p>
      </div>

      {isOpen && (
        <div className="lightboxOverlay" onClick={handleCloseModal}>
          <Lightbox
            open={isOpen}
            close={handleCloseModal}
            slides={[
              {
                src: mediaUrl,
                type: mediaType === "video" ? "video" : "image",
              },
            ]}
            onClick={(e) => e.stopPropagation()}
            render={{
              slide: renderSlide,
              buttonPrev: () => null,
              buttonNext: () => null,
            }}
            carousel={{
              preload: 0,
              finite: true,
            }}
            controller={{
              touchAction: "none",
              closeOnPullDown: false,
              closeOnBackdropClick: true,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default NewsGalleryComponent;
