import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "../scss/components/_gallery.scss";

import gi1 from "../img/gi1.png";
import gi2 from "../img/gi2.png";
import gi3 from "../img/gi3.png";
import gi4 from "../img/gi4.png";
import gi5 from "../img/gi5.png";
import gi6 from "../img/gi6.png";
import gi7 from "../img/gi7.png";
import gi8 from "../img/gi8.png";
import gi9 from "../img/gi9.png";
import gi10 from "../img/gi10.png";

const Gallery = () => {
  const { t } = useTranslation();
  const images = [gi1, gi2, gi3, gi4, gi5, gi6, gi7, gi8, gi9, gi10];

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [showArrows, setShowArrows] = useState(false);
  const lightboxRef = useRef(null);

  // Переключение изображений
  const handlePrev = () => {
    setPhotoIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setPhotoIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) return;
    const handleKeyDown = (event) => {
      if (event.key === "Escape") handleClose();
      if (event.key === "ArrowLeft") handlePrev();
      if (event.key === "ArrowRight") handleNext();
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [isOpen]);

  return (
    <div className="gallery" id="gallery">
      <div className="content">
        <h1>{t("gallery.headline")}</h1>
        <div className="galleryBox">
          {images.map((imgSrc, index) => (
            <div key={index} className="galleryImageBox">
              <img
                src={imgSrc}
                alt={`Gallery Image ${index + 1}`}
                onClick={() => {
                  setPhotoIndex(index);
                  setIsOpen(true);
                  setShowArrows(true);
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
          ))}
        </div>
      </div>

      {isOpen && (
        <div
          className="lightboxOverlay"
          ref={lightboxRef}
          onClick={handleClose}
          onMouseEnter={() => setShowArrows(true)}
        >
          <button className="lightbox-close" onClick={handleClose}>
            ✕
          </button>

          <div className="lightbox-content">
            {showArrows && (
              <button
                className="lightbox-prev"
                onClick={(e) => {
                  e.stopPropagation();
                  handlePrev();
                }}
              >
                ❮
              </button>
            )}

            <img
              src={images[photoIndex]}
              className="lightbox-image"
              alt="Gallery"
              onClick={(e) => e.stopPropagation()}
            />

            {showArrows && (
              <button
                className="lightbox-next"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNext();
                }}
              >
                ❯
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
